<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Informasi Data Karyawan">
        <b-row class="mt-2 mb-2" align="end">
          <b-col md="3">
            <b-form-input
              id="name"
              v-model="name"
              placeholder="Cari Nama Karyawan"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="3">
            <v-select
              v-model="position"
              :options="positionOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              placeholder="Pilih Posisi"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="2" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>

          <b-col align="end" md="1"></b-col>

          <b-col align="end" md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="formTambah()"
            >
              <feather-icon icon="PlusIcon" class="mr-50" />
              <span class="align-middle">Tambah</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-if="props.column.field === 'total_point'">
              {{ props.row.position === 'MARKETING' ? props.row.total_point + " / " + props.row.target_point : '-' }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                v-b-tooltip.hover.v-primary
                title="Detail"
                class="btn-icon mr-2"
                @click="formDetail(props)"
              >
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                v-b-tooltip.hover.v-warning
                title="Ubah"
                class="btn-icon mr-2"
                @click="formEdit(props)"
              >
                <feather-icon icon="EditIcon" class="cursor-pointer" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                v-b-tooltip.hover.v-danger
                title="Hapus"
                class="btn-icon"
                @click="deleteInfo(props)"
              >
                <feather-icon icon="Trash2Icon" class="cursor-pointer" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      required,
      email,

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
          width: "70px",
        },
        {
          label: "Nama Lengkap",
          field: "name",
          thClass: "text-center",
        },
        {
          label: "Posisi",
          field: "position",
          thClass: "text-center",
        },
        {
          label: "No. HP",
          field: "phone",
          thClass: "text-center",
        },
        {
          label: "Email",
          field: "email",
          thClass: "text-center",
        },
        {
          label: "Point",
          field: "total_point",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Aksi",
          field: "action",
          thClass: "text-center",
          tdClass: "text-center",
          width: "200px",
        },
      ],
      rows: [],

      tanggal_filter: "",

      configs: {
        mode: "range",
        allowInput: {
          allowInput: true,
          dateFormat: "Y-m-d",
        },
      },

      positionOptions: [],

      // positionOptions: [
      //   {
      //     _id: "MANAGER",
      //     name: "MANAGER",
      //   },
      //   {
      //     _id: "STAFF",
      //     name: "STAFF",
      //   },
      //   {
      //     _id: "MARKETING",
      //     name: "MARKETING",
      //   },
      //   {
      //     _id: "ACCOUNTING",
      //     name: "ACCOUNTING",
      //   },
      // ],

      // old delete
      searchTerm: "",

      name: "",
      position: "",

      sort: "",

      create_time: "",

      show: false,
      aksi: "",

      nama: "",

      selected: null,
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
    this.getPosition();
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search0 = "";
      if (this.name) {
        search0 = "&name=" + this.name;
      } else {
        search0;
      }

      let search1 = "";
      if (this.position) {
        search1 = "&position=" + this.position;
      } else {
        search1;
      }

      return new Promise(() => {
        axios
          .get(
            "v1/employee/all?" +
              "page=" +
              page +
              "&size=" +
              size +
              "&sort_by=create_time&sort_dir=-1" +
              search0 +
              search1
          )
          .then((res) => {
            this.rows = res.data.content;

            this.paging.page = res.data.page;
            this.paging.size = res.data.size;
            this.paging.total_elements = res.data.total_elements;
            this.paging.total_pages = res.data.total_pages;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    formTambah() {
      this.$router.push("/karyawan/form");
    },

    formEdit(props) {
      this.$router.push("/karyawan/form/" + props.row._id);
    },

    formDetail(props) {
      this.$router.push("/karyawan/form/" + props.row._id + "/detail");
    },

    deleteInfo(props) {
      this.$swal({
        title: "Peringatan!",
        text: "Apakah anda yakin menghapus data karyawan ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("v1/employee/" + props.row._id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data Karyawan Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    getPosition() {
      return new Promise(() => {
        axios.get("v1/employee/combo/position").then((res) => {
          this.positionOptions = res.data;
        });
      });
    },

    pilihKavling(event) {
      this.formSubmit.kavling_id = event;
    },

    resetFilter() {
      this.name = "";
      this.position = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
